(function($R)
{
    $R.lang['ru'] = {
        "format": "Формат",
        "image": "Картинка",
        "file": "Файл",
        "link": "Ссылка",
        "bold": "Полужирный",
        "italic": "Курсив",
        "deleted": "Зачеркнутый",
        "underline": "Подчеркнутый",
        "superscript": "Надстрочный",
        "subscript": "Подстрочный",
        "bold-abbr": "B",
        "italic-abbr": "I",
        "deleted-abbr": "S",
        "underline-abbr": "U",
        "superscript-abbr": "Sup",
        "subscript-abbr": "Sub",
        "lists": "Списки",
        "link-insert": "Вставить ссылку",
        "link-edit": "Редактировать ссылку",
        "link-in-new-tab": "Открыть ссылку в новом табе",
        "unlink": "Удалить ссылку",
        "cancel": "Отменить",
        "close": "Закрыть",
        "insert": "Вставить",
        "save": "Сохранить",
        "delete": "Удалить",
        "text": "Текст",
        "edit": "Редактировать",
        "title": "Title",
        "alt": "Текстовое описание картинки",
        "paragraph": "Обычный текст",
        "quote": "Цитата",
        "code": "Код",
        "heading1": "Заголовок 1",
        "heading2": "Заголовок 2",
        "heading3": "Заголовок 3",
        "heading4": "Заголовок 4",
        "heading5": "Заголовок 5",
        "heading6": "Заголовок 6",
        "filename": "Имя файла",
        "optional": "необязательно",
        "unorderedlist": "Ненумерованный список",
        "orderedlist": "Нумерованный список",
        "outdent": "Убрать отступ",
        "indent": "Добавить отступ",
        "horizontalrule": "Линия",
        "upload": "Загрузить",
        "upload-label": "Перетащите файлы или нажмите для загрузки",
        "upload-change-label": "Перетащите новую картинку",
        "accessibility-help-label": "Редактор форматированного текста",
        "caption": "Подпись",
        "bulletslist": "Маркеры",
        "numberslist": "Нумерация",
        "image-position": "Обтекание",
        "none": "Нет",
        "left": "Слева",
        "right": "Справа",
        "center": "По центру",
        "undo": "Отменить",
        "redo": "Повторить"
    };
})(Redactor);
