import Redactor from './redactor';

import './_langs/en';
import './_langs/ru';

import './_plugins/clips/clips';
import './_plugins/clips/clips.css';
import './_plugins/counter/counter';
import './_plugins/fullscreen/fullscreen';
import './_plugins/imagemanager/imagemanager';
import './_plugins/properties/properties';
import './_plugins/table/table';
import './_plugins/video/video';

import './styles.scss';

Redactor.addLang('ru', {
    // alignment
    'align': 'Выравнивание',
    'align-left': 'Выравнивание слева',
    'align-center': 'Выравнивание по центру',
    'align-right': 'Выравнивание справа',
    'align-justify': 'Выравнивание по краям',

    // clips
    'clips': 'Вёрстка',
    'clips-select': 'Выберите вариант для вставки',

    // counter
    'words': 'слов',
    'chars': 'знаков',

    // fullscreen
    'fullscreen': 'На полный экран',

    // imagemanager
    'choose': 'Выберите',

    // properties
    'properties': 'Свойства',

    // table
    'table': 'Таблица',
    'insert-table': 'Вставить таблицу',
    'insert-row-above': 'Вставить строку выше',
    'insert-row-below': 'Вставить строку ниже',
    'insert-column-left': 'Вставить колонку слева',
    'insert-column-right': 'Вставить колонку справа',
    'add-head': 'Добавить заголовок таблицы',
    'delete-head': 'Удалить заголовок таблицы',
    'delete-column': 'Удалить колонку',
    'delete-row': 'Удалить строку',
    'delete-table': 'Удалить таблицу',

    // video
    'video': 'Видео',
    'video-html-code': 'Код для вставки видео или ссылка на Youtube / RuTube / ВК / Яндес.Облако'
});

Redactor.options = {
    lang: 'ru',
    buttons: ['html', 'format', 'bold', 'italic', 'underline', 'sup', 'sub', 'lists', 'image', 'link'],
    replaceTags: {
        'h1': 'h2',
        'b': 'strong',
        'i': 'em'
    },
    formatting: ['p', 'blockquote', 'h2', 'h3'],
    formattingAdd: {
        'info-add': {
            title: 'Инфо',
            api: 'module.block.format',
            args: {
                'tag': 'p',
                'attr': {
                    'class': 'info'
                },
                'type': 'toggle'
            }
        },
        'tizer-add': {
            title: 'Тизер',
            api: 'module.block.format',
            args: {
                'tag': 'p',
                'attr': {
                    'class': 'tizer'
                },
                'type': 'toggle'
            }
        },
        'lead': {
            title: 'Большой абзац',
            api: 'module.block.format',
            args: {
                'tag': 'p',
                'attr': {
                    'class': 'lead'
                },
                'type': 'toggle'
            }
        }
    },
    minHeight: '300px',

    imagePosition: {
        left: 'figure-left',
        right: 'figure-right'
    },
    imageResizable: true,
    imageObserve: false,

    linkTarget: '_blank',
    linkTitle: true,
    linkSize: 100,

    plugins: ['clips', 'counter', 'fullscreen', 'imagemanager', 'properties', 'table', 'video'],

    callbacks: {
        image: {
            uploaded: function (image, response) {
                const width = response.file.width;
                const height = response.file.height;
                const img = image.getElementsByTagName('img')[0];

                if (width > 0 && height > 0) {
                    img.setAttribute('width', response.file.width);
                    img.setAttribute('height', response.file.height);
                }
            },
            uploadError: function (response) {
                alert(response.message);
            }
        }
    },
    grammarly: false,
    pasteLinkTarget: '_blank',

    autoparseLinks: false,
    autoparseImages: false,
    autoparseHttps: true,

    styles: false,
    stylesClass: 'redactor-styles',
    toolbarFixedTopOffset: 0,
    multipleUpload: false,

    clips: [
        [
            'Две колонки 25/75',
            '<div class="row">' +
            '<div class="column medium-3"><p>Текст</p></div>' +
            '<div class="column medium-9"><p>Текст</p></div>' +
            '</div>'
        ],
        [
            'Две колонки 50/50',
            '<div class="row">' +
            '<div class="column medium-6"><p>Текст</p></div>' +
            '<div class="column medium-6"><p>Текст</p></div>' +
            '</div>'
        ],
        [
            'Две колонки 75/25',
            '<div class="row">' +
            '<div class="column medium-9"><p>Текст</p></div>' +
            '<div class="column medium-3"><p>Текст</p></div>' +
            '</div>'
        ],
        [
            'Три колонки по 33%',
            '<div class="row">' +
            '<div class="column medium-4"><p>Текст</p></div>' +
            '<div class="column medium-4"><p>Текст</p></div>' +
            '<div class="column medium-4"><p>Текст</p></div>' +
            '</div>'
        ],
        [
            'Три колонки по 25/50/25',
            '<div class="row">' +
            '<div class="column medium-3"><p>Текст</p></div>' +
            '<div class="column medium-6"><p>Текст</p></div>' +
            '<div class="column medium-3"><p>Текст</p></div>' +
            '</div>'
        ],
        [
            'Кнопка «Зарегистрироваться»',
            '<div class="text-center">' +
            '<a class="button san-marino large" href="#" target="_blank">Зарегистрироваться</a>' +
            '</div>'
        ],
        [
            'Фотография 360°',
            '<div data-panorama=""></div>'
        ],
    ]
};

export { Redactor };
